.CorporateDetail{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.CorporateDetail .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}


.corporate-detail{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 14px;
    width: 100%;
}


.corporate-detail .dashboard-title {
    font-size: 22px;
    color: #1D1C1C;
    font-weight: 600;
    margin-bottom: 0px;
}

.corporate-detail .dashboard-subtitle {
    color: #555;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

.corporate-detail .sub-title {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.corporate-detail .sub-title-1 {
    color: #747474;
    font-size: 16px;
}

.corporate-detail-title {
    align-items: flex-start;
    color: #636773;
    display: flex;
    font-size: 18px;
    font-weight: 500;
    justify-content: space-between;
    margin-bottom: .5rem;
}

.corporate-detail-subtitle {
    color: #636773;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
}
.corporate-detail-subtitle span {
    color: #333 !important;
    font-weight: 600;
}

.corporate-detail .progress-bar-container {
    width: 100%;
    margin: 0;
    position: relative;
  }
  
.corporate-detail .progress-bar {
    position: relative;
    width: 100%;
    height: 14px;
    border-radius: 10px;
    overflow: visible;
}
  
.corporate-detail .progress {
    height: 100%;
    background: #dde1e6;
    position: relative;
    z-index: 1;
}

.corporate-detail .bgBlue {
    background: rgb(0,121,200);
    background: -moz-linear-gradient(90deg, rgba(0,121,200,1) 0%, rgba(105,41,196,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0,121,200,1) 0%, rgba(105,41,196,1) 100%);
    background: linear-gradient(90deg, rgba(0,121,200,1) 0%, rgba(105,41,196,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0079c8",endColorstr="#6929c4",GradientType=1);
}

.corporate-detail .bgPink {
    background: rgb(208,38,112);
    background: -moz-linear-gradient(90deg, rgba(208,38,112,1) 0%, rgba(183,41,0,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(208,38,112,1) 0%, rgba(183,41,0,1) 100%);
    background: linear-gradient(90deg, rgba(208,38,112,1) 0%, rgba(183,41,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d02670",endColorstr="#b72900",GradientType=1);
}

.corporate-detail .bgLightBlue {
    background: rgb(81,160,220);
    background: -moz-linear-gradient(90deg, rgba(81,160,220,1) 0%, rgba(5,184,181,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(81,160,220,1) 0%, rgba(5,184,181,1) 100%);
    background: linear-gradient(90deg, rgba(81,160,220,1) 0%, rgba(5,184,181,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#51a0dc",endColorstr="#05b8b5",GradientType=1);
}

.corporate-detail .bgLightGreen {
    background: rgb(72,192,106);
    background: -moz-linear-gradient(90deg, rgba(72,192,106,1) 0%, rgba(0,125,121,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(72,192,106,1) 0%, rgba(0,125,121,1) 100%);
    background: linear-gradient(90deg, rgba(72,192,106,1) 0%, rgba(0,125,121,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#48c06a",endColorstr="#007d79",GradientType=1);
}

.corporate-detail .bgLightOrange {
    background: rgb(241,173,113);
    background: -moz-linear-gradient(90deg, rgba(241,173,113,1) 0%, rgba(208,38,112,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(241,173,113,1) 0%, rgba(208,38,112,1) 100%);
    background: linear-gradient(90deg, rgba(241,173,113,1) 0%, rgba(208,38,112,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f1ad71",endColorstr="#d02670",GradientType=1);
}

.corporate-detail .bgLightPurple {
    background: rgb(184,138,246);
    background: -moz-linear-gradient(90deg, rgba(184,138,246,1) 64%, rgba(250,166,201,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(184,138,246,1) 64%, rgba(250,166,201,1) 100%);
    background: linear-gradient(90deg, rgba(184,138,246,1) 64%, rgba(250,166,201,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b88af6",endColorstr="#faa6c9",GradientType=1);
}


.css-DFA-Icon {
    width: 16px;
    height: 16px;
    color: #929ba2;
}

.corporate-detail li.list-group-item:last-child{
    padding-bottom:  0rem !important;
}