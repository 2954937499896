
.PracticeConnect{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.PracticeConnect .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.practice-connect{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 10px;
    width: 100%;
}
.practice-connect .sub-title {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.practice-connect .bg-grey {
    background-color: #f0f4ff;
}
.practice-connect .card-title1 {
    color: #333;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: .3rem;
    text-align: left;
}
.practice-connect .card-text1 {
    color: #636773;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    text-align: left;
}
.eFEHrh div:first-child{
    white-space: normal !important;
}

.TablePracticeConnect .gdBOih:nth-child(1), .TablePracticeConnect .dBiUCQ:nth-child(1){
    min-width: 300px !important;
}

.TablePracticeConnect .gdBOih:nth-child(2), .TablePracticeConnect .dBiUCQ:nth-child(2){
    min-width: 200px !important;
}

.TablePracticeConnect .gdBOih:nth-child(3), .TablePracticeConnect .dBiUCQ:nth-child(3){
    min-width: 300px !important;
}

.TablePracticeConnect .gdBOih:nth-child(4), .TablePracticeConnect .dBiUCQ:nth-child(4){
    min-width: 100px !important;
}

.TablePracticeConnect .gdBOih:nth-child(5), .TablePracticeConnect .dBiUCQ:nth-child(5){
    min-width: 100px !important;
}

.TablePracticeConnect .gdBOih:nth-child(8), .TablePracticeConnect .dBiUCQ:nth-child(6){
    min-width: 60px !important;
}


.tableExistingChallenges .gdBOih:nth-child(1), .tableExistingChallenges .dBiUCQ:nth-child(1){
    min-width: 240px !important;
}

.tableExistingChallenges .gdBOih:nth-child(2), .tableExistingChallenges .dBiUCQ:nth-child(2){
    min-width: 240px !important;
}

.tableExistingChallenges .gdBOih:nth-child(6), .tableExistingChallenges .dBiUCQ:nth-child(6){
    min-width: 360px !important;
}



#addNewDivisionPopUps .modal-title{
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
  }
  
#addNewDivisionPopUps .modal-header {
    display: flex;
    flex-shrink: 0;
    flex-direction: row !important;
    align-items: flex-start;
    flex-wrap: nowrap;
}

#addPracticePopUps .modal-title{
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
  }
  
  #addPracticePopUps .modal-header {
    display: flex;
    flex-shrink: 0;
    flex-direction: row !important;
    align-items: flex-start;
    flex-wrap: nowrap;
}

#onboarDoctorsPopUps .modal-title{
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
  }
  
  #onboarDoctorsPopUps .modal-header {
    display: flex;
    flex-shrink: 0;
    flex-direction: row !important;
    align-items: flex-start;
    flex-wrap: nowrap;
}
