@media print {
    @page {
        size: A4;
        margin: 0;
    }
    body {
        width: 210mm;
        height: 297mm;
        margin: 0;
        font-family: Arial, sans-serif;
        color: #333;
        /* padding: 20px; */
    }
}
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 28px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }
    .title {
        font-size: 24px;
        color: #ff9f43;
        font-weight: bold;
    }
    .patient_info {
        font-size: 12px;
        color: #666;
    }
    .main_stats {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .stat_item {
        display: flex;
        align-items: center;
    }
    .stat_icon {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }
    .stat_img {
        width: 100%;
        height: 100%;
    }
    .stat_info {
        font-size: 14px;
    }
    .stat_value {
        font-size: 24px;
        font-weight: bold;
    }
    .health_grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        margin-bottom: 20px;
    }
    .health_item {
        background-color: #f0f0f0;
        padding: 18px;
        text-align: center;
        position: relative;
    }
    .health_icon {
        width: 40px;
        height: 40px;
        margin: 0 auto 10px;
    }
    .health_status {
        position: absolute;
        top: 10px;
        right: 0;
        background-color: #cccccc;
        border-radius: 50px 0 0 50px;
        padding: 4px 6px;
        font-size: 10px;
        font-weight: bold;
        color: #ff0000;
    }
    .health_img {
        width: 100%;
        height: 100%;
    }
    .health_label {
        font-size: 12px;
        margin-bottom: 5px;
    }
    .health_value {
        font-size: 16px;
        font-weight: bold;
    }
    .advisory_section {
        padding: 10px 15px;
        margin-bottom: 20px;
        display: flex;
    }
    .advisory_title {
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-right: 5px;
    }
    .advisory_title_img {
        width: 60px;
    }
    .advisory_title_small {
        font-size: 14px;
    }
    .advisory_title_big {
        font-size: 24px;
        font-weight: bolder;
        margin-bottom: 0;
    }
    .advisory_content {
        display: flex;
        justify-content: space-between;
    }
    .advisory_column {
        width: 50%;
    }
    .advisory_column h4 {
        background-color: #ffffff;
        width: max-content;
        padding: 0 5px;
        border-radius: 50px;
    }
    .advisory_list li {
        font-size: 10px;
    }
    .future_tests {
        background-color: #f9f9f9;
        padding: 15px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }