
.control-input-ui-addmember {
    border: 1px solid #BBBBBB !important;
    border-radius: 14px !important;
    color: #343434 !important;
    font-size: 18px !important;
    background-color: #fdfdfd !important;
    padding: 8px 12px !important;
}
.control-input-ui-addmember:disabled{
    background-color: #fff !important;
    border: 1px solid #fff !important;
    font-size: 17px !important;
    padding: 1px 0px !important;
    line-height: 1.2;
    font-weight: 600;
    resize: none;
}
.control-input-ui-addmember::placeholder {
    font-size: 14px !important;
}

.expandable-title {
    font-size: 18px;
    color: #03335b;
    font-weight: 600;
    line-height: 1.2;
    background-color: #f1f5fc;
    width: 100%;
    display: block;
    padding: 8px 10px;
    border-radius: 6px;
    text-decoration: none;
}

.expandable-title:hover {
    background-color: #1279be ;
    color: #fff;
}

.expandable-title.active{
    background-color: #1279be ;
    color: #fff;
}

.DefaultLeaderBoardimage {
    height: 180px;
    position: relative;
    width: 180px;
}

.profile-image {
    flex: 0 0 20%;
    position: relative;
}
.profile-image img {
    display: block;
    border-radius: 10px;
    object-fit: cover;
    height: 180px;
    width: 160px;
}
.icon-edit-btn {
    background-color: #03335b !important;
    border: 0!important;
    border-radius: 30px!important;
    bottom: 6px!important;
    height: 42px!important;
    position: absolute!important;
    right: 6px!important;
    width: 42px!important;
}
.icon-edit-btn:hover {
    background-color: #1279be !important;
    border: 0!important;
    border-radius: 30px!important;
    bottom: 6px!important;
    height: 42px!important;
    position: absolute!important;
    right: 6px!important;
    width: 42px!important;
}

.messageDivNew {
    background-color: transparent !important;
    color: #000000 !important;
    padding: 0px !important;
    margin:0px!important;
    margin-top: 5px!important;
    font-weight: 400 !important;
    margin-bottom: 5px!important;
    border-radius: 10px!important;
    border: 1px solid transparent !important;
  }