.box {
    border: 0px solid #d5d5d5;
    padding: 10px;
    margin: 10px;
    text-align: center;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    border: 0px;
    border-radius: 4px;
}

.box .box1 {
    border-left: 10px solid orange !important;
}

.working-plans-btn {
    color: #fff !important;
    background: #03335b !important;
    border: 1px solid #03335b !important;
    font-size: 18px !important;
    padding: 12px 20px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
}

.working-plans-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
}

.green {
    color: #2cb742;
}

.blue {
    color: #007eff;
}

.txt-small {
    font-size: 12px;
    font-weight: 600;
    color: #aca7a6;
}

.tasks span {
    padding-right: 10px;
}

.align-left {
    text-align: left;
}

.pad-10 {
    padding: 10px;
}

.progress-bar-appointment {
    height: 2px !important;
}

.orange-box {
    background-color: orange;
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    font-weight: bold;
}

.green-icon-number-box {
    background-color: green;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.green-icon-number-box i {
    font-size: 10px;
}

.green-icon-number-box span {
    font-size: 12px;
}

.text-right {
    text-align: right;
}

.pad-left {
    padding-left: 25px !important;
}

.font-small {
    font-size: 12px;
}

.font-medium {
    font-size: 14px;
}

.bold {
    font-weight: bold;
}

.text-left {
    text-align: left;
}

.tabs {
    display: flex;
    justify-content: left;
}

.active-tab {
    border-bottom: 2px solid #0017e2 !important;
    font-weight: 600;
    color: #0017e2 !important;
}

.tab {
    padding: 5px 0px;
    cursor: pointer;
    margin: 0 0px;
    font-size: 15px;
    color: black;
    font-weight: 600;
    text-decoration: none;
    margin: 0 10px;
}

.tab:hover {
    border-bottom: 2px solid #69f !important;
    font-weight: bold;
    color: #69f !important;
    font-weight: 600;
}

.tab:target {
    background-color: #007bff;
    color: white;
}

.tab-content {
    margin-top: 0px;
    padding: 15px 10px;
    width: 100%;
    text-align: left;
}

.hide {
    display: none !important;
}

.tab-content:target {
    display: block;
}

.gray-circle {
    width: 40px;
    height: 40px;
    background-color: #e9e9e9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    color: white;
}

/*Calendar*/
.calendar {
    width: 100%;
    max-width: 1000px;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 8px;
}

.calendar-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #ddd;
}

.month {
    font-size: 1.5em;
    font-weight: bold;
}

.weekdays {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0;
    font-weight: bold;
}

.calendar-body {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1px;
}

.day {
    padding: 5px;
    border: 1px solid #ddd;
    min-height: 100px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}

.day:hover {
    background-color: #f0f0f0;
}

.empty {
    background-color: #f9f9f9;
    border: none;
    cursor: default;
}

.day-number {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 0.8em;
    font-weight: bold;
    color: #555;
}

.schedule {
    margin-top: 20px;
    padding: 3px 5px;
    background-color: #007bff;
    color: white;
    border-radius: 15px;
    font-size: 10px;
}

.calendar-legend {
    width: 20px;
    height: 20px;
    margin: 0 10px;
}

.calendar-container span {
    margin: 0 10px;
    font-size: 12px;
    /* line-height: 1.3; */
}

.legend-scheduled {
    background-color: #0077bc !important;
}

.legend-completed {
    background-color: #73c099 !important;
}

.legend-missed {
    background-color: #ffc31d !important;
}

.legend-cancelled {
    background-color: #ff645c !important;
}

.legend-unsubscribed {
    background-color: #c0c0c0 !important;
}

.mwd span {
    padding-left: 10px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.weekly-calendar-container {
    display: grid;
    grid-template-columns: 100px repeat(7, 1fr);
    width: 100%;
    max-width: 1200px;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.weekly-calendar-header {
    display: contents;
}

.weekly-time-column {
    background-color: #f9f9f9;
    border-right: 1px solid #ddd;
}

.weekly-day-column {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    background-color: #f9f9f9;
}

.weekly-calendar-body {
    display: contents;
}

.weekly-row {
    display: contents;
}

.weekly-time-label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #f9f9f9;
    height: 60px;
}

.weekly-cell {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    height: 60px;
    background-color: #fff;
    position: relative;
}

.weekly-cell:nth-child(odd) {
    background-color: #f4f4f4;
}

.weekly-event {
    position: absolute;
    background-color: #007bff;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 0.9em;
    line-height: 1.2em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
}

.day-calendar-container {
    width: 100%;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.day-calendar-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
    padding: 10px;
}

.day-header-date {
    font-size: 1.2em;
    font-weight: bold;
}

.day-calendar-body {
    display: flex;
    flex: 1;
    overflow-y: auto;
}

.day-time-column {
    width: 100px;
    border-right: 1px solid #ddd;
    background-color: #f9f9f9;
    padding: 10px 0;
}

.day-time-slot {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ddd;
    font-size: 0.8em;
    color: #555;
}

.day-events-column {
    flex: 1;
    position: relative;
}

.day-grid {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    grid-template-rows: repeat(24, 60px);
}

.day-grid-row {
    border-bottom: 1px solid #ddd;
}

.day-event {
    position: absolute;
    left: 10px;
    right: 10px;
    background-color: #007bff;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 0.9em;
    line-height: 1.2em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.hand-cursor {
    cursor: pointer;
}

.ContentDashboard {
    /* padding: 10px; */
    width: 100%;
    background-color: #f1f5fd;
}

.content-dashboard {
    background-color: #fff;
    color: #000;
    padding: 0px;
    border-radius: 14px;
    display: block;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    margin-bottom: 4rem !important;
}

.content-wrapper {
    background-color: #f1f5fd;
}

.dashboard-content-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.dashboard-content-wrapper-inner {
    width: 75%;
    height: 100%;
}

.dashboard-content-aside {
    width: 25%;
    /* background-color: #000;
    */
    position: sticky;
    top: 15%;
}

.box-content {
    padding: 14px 0px;
    border-radius: 0px 14px 14px 14px !important;
}

.box-content .card-task {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    border: 0px;
    border-radius: 4px;
}

.box-content .card-title {
    color: #7786aa;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
    text-align: left;
}

.box-content .card-title1 {
    font-size: 15px;
    color: #333;
    font-weight: 400;
    margin-bottom: 0px;
}

.box-content .card-text {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0px;
}

.box-content .task-list {
    display: flex;
    align-items: center;
}

.box-content .task-item {
    padding: 0 10px 0 0px;
    width: 100px;
}

.box-content .card-phone-icon {
    color: #3552b5;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0px;
    padding-right: 10px;
}

.box-content .card-border-orange {
    border-left: 6px solid #feb558;
}

.box-content .card-border-green {
    border-left: 6px solid #06d6a0;
}

.box-content .card-phone-message {
    color: #3552b5;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0px;
    padding-right: 10px;
}

.box-content .card-right-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #feb558;
    border-radius: 10px;
    padding: 10px;
    height: 100px;
    width: 100px;
    float: right;
}

.box-content .card-right-box h2 {
    font-size: 28px;
    font-weight: 500;
    color: #333;
}

.box-content .card-right-box-member {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #06d6a0;
    border-radius: 8px;
    padding: 2px 8px;
}

.box-content .card-right-box-member h2 {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
}

.box-content .diabeticdata h3 {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 0px;
    text-align: center;
}

.box-content .diabeticdata h3 span.green-text {
    font-weight: 700;
    color: #00e02f;
}

.box-content .diabeticdata h3 span.red-text {
    font-weight: 700;
    color: #f70000;
}

.box-content .legend-box {
    border-radius: 4px;
    width: 14px !important;
    height: 14px !important;
}

.box-content .calendar-status-text {
    color: #656565;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
}

.box-content .dropdwon-input {
    position: absolute;
    right: 20px;
    z-index: 999;
}

.box-content .dropdwon-input-box {
    border: 0;
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

/* Custom styles for the previous button */
.swiper-button-prev:after {
    content: none;
    width: 30px;
    /* Remove default content */
    /* Adjust width */
    height: 30px;
    /* Adjust height */
    display: inline-block;
    /* Ensure the icon displays */
    /* Additional custom styles */
    color: #333;
    /* Example: change color */
    border-radius: 50%;
    /* Example: make it circular */
    background-size: contain;
    /* Ensure background image fits */
    font-size: 30px !important;
}

/* Custom styles for the next button */
.swiper-button-next:after {
    content: '';
    /* Remove default content */
    width: 30px;
    /* Adjust width */
    height: 30px;
    /* Adjust height */
    display: inline-block;
    /* Ensure the icon displays */
    /* Additional custom styles */
    color: #333;
    /* Example: change color */
    border-radius: 50%;
    /* Example: make it circular */
    background-size: contain;
    /* Ensure background image fits */
    font-size: 30px !important;
}

.swiper-pagination-bullet-active {
    background: #03335b !important;
}

.dashboard-graph-box-cn .table-container {
    display: flex;
    justify-content: center;
    /* margin: 20px;
    */
}

.dashboard-graph-box-cn .styled-table {
    border-collapse: collapse;
    width: 100%;
}

.dashboard-graph-box-cn .styled-table th,
.dashboard-graph-box-cn .styled-table td {
    border: 1px solid #bababa;
    text-align: center;
    padding: 8px;
}

.dashboard-graph-box-cn .styled-table .table-title {
    font-size: 20px;
    font-weight: 500 !important;
    color: #333;
    margin-bottom: 0.3rem;
    text-align: center;
}

.dashboard-graph-box-cn .styled-table .table-number {
    font-size: 26px;
    font-weight: 600 !important;
    color: #333;
    margin-bottom: 0.3rem;
    text-align: center;
}

.dashboard-graph-box-cn .styled-table span {
    font-size: 13px;
    font-weight: 500;
}

.dashboard-graph-box-cn .styled-table th {
    background-color: #d4e6ef;
    font-weight: 500;
    font-size: 15px;
    color: #333;
}

.dashboard-graph-box-cn .styled-table .brd-l {
    border-left: 2px solid #8d8d8d;
}

.dashboard-graph-box-cn .styled-table .brd-r {
    border-right: 2px solid #8d8d8d;
}

.dashboard-graph-box-cn .red-text {
    color: red;
}

.dashboard-graph-box-cn .green-text {
    color: green;
}

.dashboard-graph-box-cn .styled-table .bg-lightblue {
    background-color: #f0f4ff !important;
}

.no-data-box {
    display: flex;
    width: 100%;
    height: 417px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#appointmentSchedule .modal-header {
    display: flex;
    flex-shrink: 0;
    flex-direction: row !important;
    align-items: flex-start;
    align-content: center;

}

#appointmentSchedule .modal-title {
    font-size: 20px;
    color: #404040;
    font-weight: 600;
    line-height: 1.2;
}

#externalMemberSearch .modal-header {
    display: flex;
    flex-shrink: 0;
    flex-direction: row !important;
    align-items: flex-start;
    align-content: center;
}

#externalMemberSearch .modal-title {
    font-size: 20px;
    color: #404040;
    font-weight: 600;
    line-height: 1.2;
}

#wpnb .modal-header {
    display: flex;
    flex-shrink: 0;
    flex-direction: row !important;
    align-items: flex-start;
    align-content: center;
}

#wpnb .modal-title {
    font-size: 20px;
    color: #404040;
    font-weight: 600;
    line-height: 1.2;
}

#memberProfileDetails .modal-header {
    display: flex;
    flex-shrink: 0;
    flex-direction: row !important;
    align-items: flex-start;
    align-content: center;
}

#memberProfileDetails .modal-title {
    font-size: 20px;
    color: #404040;
    font-weight: 600;
    line-height: 1.2;
}

.Completed {
    color: #84bd93 !important;
}

.Delayed {
    color: #F76E66 !important;
}

.control-input-ui-dashboard {
    border: 1px solid #BBBBBB !important;
    border-radius: 10px !important;
    color: #343434 !important;
    font-size: 14px !important;
    background-color: #fdfdfd !important;
    padding: 10px 6px !important;
}

.select-input-ui-dashboard  {
    border: 1px solid #BBBBBB !important;
    border-radius: 10px !important;
    color: #343434 !important;
    font-size: 14px !important;
    background-color: #fdfdfd !important;
    padding: 10px 6px !important;
    width: 100% !important;
}