

    .profile-img {
        width: 80px;
        height: 80px;
        border-radius: 50px;
        margin-bottom: 0.5rem;
        border: 1px solid #e9e9e9;
        object-fit: cover;
    }

    .imageContainer {
        text-decoration: none;
        color: #333;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1;
        margin-bottom: 0rem;
        text-align: center;
    }
    .imageContainer  a {
            text-decoration: none;
            color: #333;
            font-size: 16px;
            font-stretch: normal;
            font-style: normal;
            font-weight: 400;
            letter-spacing: normal;
            line-height: 1;
            margin-bottom: 0rem;
        }
    .Employees-title {
        color: #333;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
    }