
.BlockerPopUps{
    /* padding: 10px 20px; */
    margin-bottom: 0rem !important;
}

.BlockerPopUps .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.blocker-pop-ups{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 14px;
    width: 100%;
}
.blocker-pop-ups .sub-title {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}



.eFEHrh div:first-child{
    white-space: normal !important;
}

.tableBlockerPopUps .gdBOih:nth-child(1), .tableBlockerPopUps .dBiUCQ:nth-child(1){
    min-width: 300px !important;
}

.tableBlockerPopUps .gdBOih:nth-child(8), .tableBlockerPopUps .dBiUCQ:nth-child(6){
    min-width: 60px !important;
}