@media print {
    @page {
        size: A4;
        margin: 0;
    }
    body {
        width: 210mm;
        height: 297mm;
        margin: 0;
        font-family: Arial, sans-serif;
        color: #333;
        /* padding: 20px; */
        font-size: 9px;
    }
}
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
    }
    .title {
        font-size: 24px;
        color: #f4a37f;
        font-weight: bold;
    }
    .patient_info {
        font-size: 12px;
        color: #666;
    }
    .note {
        background-color: #f0f0f0;
        padding: 10px;
        margin-bottom: 10px;
        position: relative;
        font-size: 12px;
        line-height: 1.2;
    }
    .sample_id {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #ff00ff;
        color: white;
        padding: 2px 8px;
        border-radius: 12px;
        font-size: 10px;
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    th, td {
        border: 1px solid #efefef;
        padding: 3px 5px;
        text-align: left;
        font-size: 16px;
    }
    th {
        background-color: #caf0f9;
        font-weight: normal;
    }
    .test_category {
        background-color: #e2f6fb;
        font-weight: bold;
        font-size: 18px;
    }
    .trends_header {
        text-align: center;
        font-weight: bold;
    }
    .merged_header {
        font-weight: bold;
        background-color: #caf0f9;
    }