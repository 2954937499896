
.SMSCampaigns{
    /* padding: 10px 20px; */
    margin-bottom: 0rem !important;
}

.SMSCampaigns .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.sms-campaigns{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 14px;
    width: 100%;
}
.sms-campaigns .sub-title {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}



.eFEHrh div:first-child{
    white-space: normal !important;
}

.tableSMSCampaigns .gdBOih:nth-child(1), .tableSMSCampaigns .dBiUCQ:nth-child(1){
    min-width: 300px !important;
}

.tableSMSCampaigns .gdBOih:nth-child(8), .tableSMSCampaigns .dBiUCQ:nth-child(6){
    min-width: 60px !important;
}