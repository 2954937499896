.WhatsAppTemplates{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.WhatsAppTemplates .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.whatsapp-templates{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    margin-bottom: 4rem !important;
    padding: 10px;
    width: 100%;
}

.whatsapp-templates .boxbg-card{
    background-color: #F5F5F5;
    border-radius: 14px;
    padding: 10px;
    width: 100%;
    height: 100%;
    border: 1px solid #707070;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.whatsapp-templates .boxbg-card.active{
    background-color: #fff;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    border: 1px solid #c0c0c0;
}

.whatsapp-templates .card-icon-tick {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.whatsapp-templates .card-images {
    /* height: 100px; */
    margin-bottom: 1rem;
    border-radius: 10px;
}
  
.whatsapp-templates .card-title {
  font-size:42px; 
  font-weight: 600; 
  color: #333; 
  margin-bottom: 0.3rem; 
}

.whatsapp-templates .card-text {
  font-size: 15px;
  color: #636773;
  margin-top: 0rem;
  font-weight: 400;
  text-align: center;
}

.whatsapp-templates .last-sync {
    color: #636773;
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    margin-bottom: 0px;
}

.whatsapp-templates .last-sync span{
    font-weight: 600;
}