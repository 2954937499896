
.box1insurePlusCover {
    border: 0px solid #e9e9e9;
    padding: 14px;
    margin-top: 10px;
    text-align: center;
    border-radius: 16px;
    font-weight: bold;
    margin-right: calc(var(--bs-gutter-x)* .5);
}

.box1insurePlusCover:first-child {
    margin-right: calc(var(--bs-gutter-x)* .5);
    margin-left: calc(var(--bs-gutter-x)* .5);
}

.insurePlusCover-title {
    color: #333;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 1;
    margin-top: 0.7rem;
    margin-bottom: 0.3rem;
}

.insurePlusCover-subtitle {
    color: #333;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1;
    margin-bottom: 0rem;
}

.css-SvgIconNew {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 14px;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    font-size: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    color: #333;
}