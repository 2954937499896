.userslist {
    font-size: 14px;
    border: 0px solid #e9e9e9;
    padding: 10px;
    background-color: #fff;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    border: 0px;
    border-radius: 4px;
    position: sticky;
    top: 70px;
    margin-left: 1rem;

    .pad-20 {
        padding: 20px !important;
    }

    .pad-10 {
        padding: 10px !important;
    }

    .search {
        display: flex;
        align-items: center;
        border: 1px solid #dcdcdc;
        margin-bottom: 8px;
        border-radius: 16px;
        padding: 5px 5px 5px 10px;
        background-color: #f1f5fd;


        input {
            border: none;
            outline: none;
            width: 100%;
            background-color: #f1f5fd;
            padding: 4px 4px 4px 10px;
        }

    }

    .userlistname {
        height: 63.5vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .profile-img {
        width: 54px;
        height: 54px;
        border-radius: 14px;
        object-fit: cover;
        display: block;
    }

    .row {
        display: flex;
        padding: 3px;

        a {
            text-decoration: none !important;
            font-size: 14px;
            color: #333;
            font-weight: 500;
        }

        .col {
            img {
                padding-right: 8px;
            }
        }
    }

    .name {
        font-size: 11px;
        font-weight: bold;
    }

    .text-small {
        color: #212121;
        text-decoration: none !important;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0.2rem;
    }

    .red-circle {
        background-color: red;
        color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        font-size: 12px;
        font-weight: bold;
        padding: 8px;
    }

    .circle-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .big {
        font-size: 15px;

    }

    .green {
        color: #2CB742;
    }

    .blue {
        color: #007EFF;
    }

    .orange-small {
        color: #FF8B8B;
        font-weight: bold;
        font-size: 12px;
    }

    .gray-small {
        color: gray;
        font-size: 12px;
        font-weight: bold;
    }

    .red-icon-number-box {
        background-color: #ff0000;
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        display: inline-flex;
        align-items: center;
        gap: 5px;
    }

    .red-icon-number-box span {
        font-size: 12px;
    }

    .hand-cursor {
        cursor: pointer;
    }
}

.card-member-title {
    font-size: 20px;
    color: rgb(51, 51, 51);
    font-weight: 500;
    margin-bottom: 0;

}

.all-members {
    margin-left: 5px;
    cursor: pointer;
}