
.prog {
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #fff;
    height: 117px;
    cursor: pointer;
}

.prog-lifestyle {
    background-image: url("https://storecdn.goqii.com/emailer/18161_1665732939.png");
}

.prog-weight {
    background-image: url("https://storecdn.goqii.com/emailer/64646_1665734895.png");
}

.prog-stress {
    background-image: url("https://storecdn.goqii.com/emailer/63332_1665734784.png");
}

.prog-diabetic {
    background-image: url("https://storecdn.goqii.com/emailer/62031_1665734820.png");
}

.text-div {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: 10px;
    text-align: left;
    color: #fff;
    width: 94%;
}

.prog-name {
    font-size: 20px !important;
}

.fullwidthdiv {
    width: 100%;
    height: 100%;
}

.boxCorp-prog {
    border: 0px solid #e9e9e9;
    border-radius: 16px;
    margin-top: 10px;
    padding: 14px;
    background-color: #fff;
    boxCorp-shadow: rgba(0, 0, 0, 0.06) 0px 2px 6px;
}

.boxCorp-prog .title {
    color: #323e4a;
    text-align: left;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 1;
    margin-bottom: 0rem;
    padding-bottom: 5px;
}

.boxCorp {
    border: 0px solid #e9e9e9;
    padding: 14px;
    margin: 10px;
    text-align: center;
    border-radius: 16px;
    font-weight: bold;
    boxCorp-shadow: rgba(0, 0, 0, 0.06) 0px 2px 6px;
}

.boxCorp1 {
    border: 0px solid #e9e9e9;
    padding: 14px;
    margin-top: 10px;
    text-align: center;
    border-radius: 16px;
    font-weight: bold;
    margin-right: calc(var(--bs-gutter-x)* .5);
}

.boxCorp1:first-child {
    margin-right: calc(var(--bs-gutter-x)* .5);
    margin-left: calc(var(--bs-gutter-x)* .5);
}

    .css-SvgIcon {
        background-color: rgba(255, 255, 255, 0.3);
        padding: 10px;
        width: 54px;
        height: 54px;
        border-radius: 50px;
        font-size: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .programs-title {
        color: #333333;
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 700;
        letter-spacing: normal;
        line-height: 1;
        margin-top: 0rem;
        margin-bottom: 0.3rem;
        display: flex;
        align-items: center;
    }

    .programs-subtitle {
        color: #636773;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1;
        margin-bottom: 0.7rem;
    }

    .css-fa-Icon {
        background-color: rgba(255, 255, 255, 0.8);
        padding: 10px;
        width: 22px;
        height: 22px;
        border-radius: 50px;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }