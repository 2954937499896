@media print {
    @page {
        size: A4;
        margin: 10mm;
    }
    body {
        width: 210mm;
        height: 297mm;
        margin: 0;
        font-family: Arial, sans-serif;
        color: #333;
        font-size: 8pt;
    }
    .report {
        display: flex;
        flex-direction: column;
    }
    .page {
        page-break-after: always;
    }
    .header {
        position: running(header);
    }
    .pageContent {
        padding-top: 10px; /* Adjust based on your header height */
    }
    @page {
        @top-center {
            content: element(header);
        }
    }
    .section {
        break-inside: avoid;
    }
    .testResult {
        break-inside: avoid;
        max-width: 100%;
        width: 100%;
    }
}

.report {
    padding: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.title {
    font-size: 24px;
    color: #f4a37f;
    font-weight: bold;
}

.patient_info {
    font-size: 12px;
    color: #666;
}

.section {
    margin-bottom: 30px;
    break-inside: avoid;
}

.sectionDescription {
    color: #666;
}

.iconWrapper {
    background-color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.testResult {
    transition: all 0.3s ease;
    break-inside: avoid;
}