@media print {
    @page {
        size: A4;
        margin: 0;
    }
    body {
        width: 210mm;
        height: 297mm;
        margin: 0;
        font-family: Arial, sans-serif;
        color: #333;
        /* padding: 20px; */
    }
}
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;
    }
    .header_div {
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
    }
    .title {
        font-size: 32px;
        color: #fd9b70;
        font-weight: bold;
    }
    .patient_info {
        font-size: 14px;
        color: #666;
    }
    .subtitle {
        font-size: 24px;
        color: #666;
        margin-bottom: 10px;
    }
    .charts_container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px 60px;
    }
    .chart_item {
        padding: 6px;
        border-radius: 5px;
    }
    .chart_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .chart_title {
        font-weight: bold;
        font-size: 14px;
    }
    .chart_status {
        font-size: 12px;
        padding: 4px 12px;
        border-radius: 10px;
    }
    .chart_status.good {
        background-color: #56a7fe;
        color: white;
    }
    .chart_status.concern {
        background-color: #fd9a70;
        color: white;
    }
    .chart_details {
        font-size: 12px;
        margin-bottom: 2px;
    }
    .chart_placeholder {
        background-color: #f0f0f0;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: italic;
        color: #666;
    }