.blog-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.blog-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blog-post h1 {
    font-size: 2.5rem;
    color: #333;
}

.blog-post p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
}

.comments-section {
    margin-top: 40px;
}

.comments-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.comment-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.comment-input {
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none;
}

.comment-input:focus {
    border-color: #007bff;
}

.submit-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.comments-list {
    margin-top: 20px;
}

.comment {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.comment strong {
    font-size: 1.1rem;
}

.comment p {
    font-size: 1rem;
    color: #444;
}
/* 
.header {
    text-align: center;
    padding: 20px 0;
    font-family: 'Arial', sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: #7a7a7a;
}

.navbar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0;
}

.navbar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

.navbar ul li {
    margin: 0 15px;
}

.navbar ul li a {
    text-decoration: none;
    color: #000;
    font-weight: bold;
}

.navbar ul li a:hover {
    text-decoration: underline;
} */