.CorporatesManageDetails .bg-grey {
    background-color: #f0f4ff;
    border-radius: 14px;
    border: none;
    height: 120px;
}

.CorporatesManageDetails .card-title {
    color: #fff;
    font-size: 34px;
    font-weight: 600;
    margin-bottom: .3rem;
    text-align: center;
}

.CorporatesManageDetails .card-text {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
    text-align: center;
    margin-bottom: 0rem;
}

.CorporatesManageDetails .bg-skyblue {
    background-color: #88BAFF;
    border-radius: 14px;
    border: none;
    height: 120px;
}

.CorporatesManageDetails .bg-red {
    background-color: #F99982;
    border-radius: 14px;
    border: none;
    height: 120px;
}

.CorporatesManageDetails .bg-purple {
    background-color: #9D7AF0;
    border-radius: 14px;
    border: none;
    height: 120px;
}