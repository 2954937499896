.CreateGroups{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.CreateGroups .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.create-groups{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 14px;
    width: 100%;
}
.create-groups .sub-title {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.create-groups .sub-title-1 {
    color: #747474;
    font-size: 16px;
}
